<template>
    <Component
        :is="isAsync ? 'AsyncRender' : 'section'"
        class="wrapper">
        <StickyFilters
            v-if="$slots.sticky"
            ref="stickyFilters">
            <div class="flex">
                <h2 class="has-text-weight-bold section-title">
                    {{ sectionName }}
                </h2>

                <Permissions
                    v-if="$listeners.action && actionOptions.hide !== true"
                    :permissions="actionOptions.permissions">
                    <LTooltip
                        :label="actionOptions.label"
                        :active="!!actionOptions.label"
                        position="is-right"
                        type="is-success">
                        <b-button
                            tag="button"
                            class="mt-1"
                            type="is-success is-light"
                            style="border-radius: 10px; width: 33px"
                            size="is-small"
                            @click="$emit('action')">
                            <b-icon
                                pack="fas"
                                :icon="actionOptions.icon"
                                :size="actionOptions.iconSize">
                            </b-icon>
                        </b-button>
                    </LTooltip>
                </Permissions>
              
                <slot name="buttons"></slot>
            </div>

            <slot name="verticals"></slot>

            <slot name="sticky"></slot>
        </StickyFilters>

        <template v-else>
            <h2 class="has-text-weight-bold section-title">
                {{ sectionName }}
            </h2>

            <slot name="verticals"></slot>
        </template>

        <slot></slot>
    </Component>
</template>

<script>
  import { mapState } from "vuex";
  import StickyFilters from "@/components/Common/StickyItem";
  import AsyncRender from "@/components/Common/AsyncRender";
  import Permissions from "@/components/Common/Permissions.vue";

  export default {
    name: "SectionWrapper",
    components: { Permissions, AsyncRender, StickyFilters },

    props: {
      isAsync: {
        type: Boolean,
        default: false
      },

      actionButtonOptions: {
        type: Object,
        default: () => ({})
      }
    },

    computed: {
      ...mapState({
        role: state => state.auth.role
      }),

      actionOptions () {
        const defaultOptions = {
          permissions: ["ANY"],
          icon: "plus",
          iconSize: "is-medium",
          hide: false
        };

        return { ...defaultOptions, ...this.actionButtonOptions };
      },

      sectionName () {
        const path = this.$route.name.split(":");
        return this.getNameByArray(path);
      }
    },

    methods: {
      getNameByArray (arr) {
        const path = this.$t(`${ this.role }.menu.${ arr[1] ?? arr[0] }`);

        if (typeof path === "object") {
          return path[arr[2]] ?? "-";
        } else if (typeof path === "string") {
          return path;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.section-title {
    padding: 0 1rem 1rem 1rem;
    font-size: 25px;
}
</style>
