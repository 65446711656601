<template>
    <Payments></Payments>
</template>

<script>
  import Payments from "@/components/Admin/Payments/Payments";

  export default {
    name: "PaymentsView",
    components: { Payments }
  };
</script>

<style scoped></style>
