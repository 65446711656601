<template>
    <div :style="hasWidthSections ? `min-width: ${ fullWidth }px` : null">
        <section
            class="is-sticky"
            :style="hasWidthSections ? `width: ${ wrapperWidth }px` : null">
            <slot></slot>
        </section>
    </div>
</template>

<script>
  import _debounce from "lodash/debounce";

  export default {
    name: "StickyItem",

    created () {
      this.mutationObserver = new MutationObserver(this.debounceTableResize);
      this.resizeObserver = new ResizeObserver(this.debounceDocumentResize);
    },

    mounted () {
      this.$nextTick(() => {
        this.mutationObserver.observe(this.parentNode, { childList: true, subtree: true });
      });
    },

    data () {
      return {
        fullWidth: null,
        wrapperWidth: null,
        resizeObserver: null,
        mutationObserver: null,
        wrapperElementNode: null,
        tableElementNode: null
      };
    },

    inject: ["isReduceMenu"],

    computed: {
      debounceDocumentResize () {
        return _debounce(this.calculateFiltersWidth, 400);
      },

      debounceTableResize () {
        return _debounce(this.observeResizeTableWidth, 400);
      },

      hasWidthSections () {
        return this.fullWidth && this.wrapperWidth;
      },

      parentNode () {
        return this.$parent.$el;
      },

      isReduce () {
        return this.isReduceMenu();
      }
    },

    methods: {
      /**
       * Отписывается от mutation observer и подписывается на наблюдения за обёрткой таблицы
       * @method
       * */
      observeResizeTableWidth () {
        const wrapperElementNode = this.parentNode.querySelector(".wrapper-content");
        this.tableElementNode = wrapperElementNode?.querySelector(".table");

        if (!this.tableElementNode) return;
        this.mutationObserver.disconnect();
        this.resizeObserver.observe(wrapperElementNode);
      },

      /**
       * Обновляет ширину фильтров
       * @method
       * */
      calculateFiltersWidth () {
        this.tableElementNode = this.parentNode.querySelector(".table");

        const PADDING_WRAPPER = 32;
        this.fullWidth = this.parentNode?.clientWidth > 1024 ? this.tableElementNode?.clientWidth : this.parentNode?.clientWidth;
        this.wrapperWidth = this.parentNode?.clientWidth - PADDING_WRAPPER;
      }
    },

    watch: {
      /** Следит за изменениями ширины sidebar и пересчитывает ширину для корректной анимации */
      isReduce (value) {
        const TOUCH_WIDTH = 1023;
        const WIDTH_DIFFERENCE_SIDEBAR = 139;

        if (document.body.clientWidth < TOUCH_WIDTH) return;

        if (value) {
          this.wrapperWidth += WIDTH_DIFFERENCE_SIDEBAR;
        } else {
          this.wrapperWidth -= WIDTH_DIFFERENCE_SIDEBAR;
        }

        this.debounceDocumentResize.cancel();
      },

      /** Отслеживает добавление нод узла таблицы и подписывается на изменения ширины таблицы */
      tableElementNode (value) {
        if (value) {
          this.tableElementNode = this.parentNode.querySelector(".table");
          this.resizeObserver.observe(this.tableElementNode);
        }
      }
    },

    beforeDestroy () {
      this.resizeObserver?.disconnect();
      this.mutationObserver?.disconnect();
    }
  };
</script>

<style lang="scss" scoped>
    html:not(.is-clipped) {
        body {
            &.is-mini {
                .is-sticky {
                    left: calc(#{ $aside-width-until-widescreen } + 1rem);
                }
            }

            &:not(.is-mini) {
                .is-sticky {
                    left: calc(#{ $aside-width-widescreen } + 1rem);
                }
            }
        }

        .is-sticky {
            //noinspection SassScssUnresolvedMixin
            @include touch {
                left: 1rem !important;
            }

            transition: all 250ms ease-in-out 50ms;
            position: sticky;
            z-index: 30;
        }
    }
</style>