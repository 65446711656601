var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.payments},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"loading":_vm.isLoading,"count":count,"backend-pagination":"","query-pagination":"","with-options":""},on:{"update":_vm.GET_PAYMENT_SYSTEMS}},[_c('b-table-column',{attrs:{"label":_vm.$t("admin.payments.table.labels.id")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.payments.table.labels.name")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.payments.table.labels.currency")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.currency)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.payments.table.labels.minimalPayout")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.defaultFormatter(row.minimalPayout))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.payments.table.labels.visibility")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("admin.payments.table.values.visibility." + (row.visibility))))+" ")]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isDisabled)?_c('LTooltip',{attrs:{"label":_vm.$t("admin.payments.table.buttons.dropdown.enable"),"position":"is-left","type":"is-success"}},[_c('b-button',{attrs:{"loading":_vm.loadingRowId === row.id,"icon-left":"play","rounded":"","size":"is-small","type":"is-success is-light"},on:{"click":function($event){return _vm.enablePaymentsSystem(row.id)}}})],1):_c('LTooltip',{attrs:{"label":_vm.$t("admin.payments.table.buttons.dropdown.disable"),"position":"is-left","type":"is-danger"}},[_c('b-button',{attrs:{"loading":_vm.loadingRowId === row.id,"icon-left":"stop","rounded":"","size":"is-small","type":"is-danger is-light"},on:{"click":function($event){return _vm.disablePaymentsSystem(row.id)}}})],1),_c('LTooltip',{attrs:{"label":_vm.$t("admin.payments.table.buttons.edit"),"position":"is-left"}},[_c('b-button',{attrs:{"icon-left":"pen","loading":_vm.loadingRowId === row.id,"rounded":"","size":"is-small","type":"is-info is-light"},on:{"click":function($event){return _vm.showEditModal(row)}}})],1),_c('LTooltip',{attrs:{"label":_vm.$t("admin.payments.table.buttons.delete"),"position":"is-left","type":"is-danger"}},[_c('b-button',{attrs:{"loading":_vm.loadingRowId === row.id,"icon-left":"trash-alt","icon-pack":"far","rounded":"","size":"is-small","type":"is-danger is-light"},on:{"click":function($event){return _vm.onDeletePayment(row)}}})],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }