<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoading"
        :on-cancel="() => closeModalConfirm()"
        @submit.stop.prevent="addPayment">
        <template #header>
            <slot name="title">
                <h5 class="title is-5">
                    {{ $t(`admin.payments.modal.header.${ modalHeader }`) }}
                </h5>
            </slot>
        </template>
        
        <div>
            <b-field :label="$t('admin.payments.modal.body.paymentName')">
                <LInput
                    v-model.trim="name"
                    required>
                </LInput>
            </b-field>

            <b-field :label="$t('admin.payments.modal.body.paymentIntegration.label')">
                <Select
                    v-model="integrationAlias"
                    :get-data-vuex="getPaymentIntegrations"
                    :searchable="false"
                    field="name"
                    append-to-body
                    prop="alias"
                    :required="!integrationAlias">
                </Select>
            </b-field>

            <b-field :label="$t('admin.payments.table.labels.visibility')">
                <Select
                    v-model="visibility"
                    :get-data="getVisible"
                    :searchable="false"
                    field="label"
                    prop="value">
                </Select>
            </b-field>

            <template v-if="!editablePaymentId">
                <b-field :label="$t('admin.payments.table.labels.currency')">
                    <Select
                        v-model="currency"
                        :get-data="getCurrency"
                        :searchable="false"
                        :clearable="false">
                    </Select>
                </b-field>
            </template>

            <b-field :label="$t('admin.payments.modal.body.minPayout')">
                <LInput
                    v-model.number="minimalPayout"
                    type="number"
                    min="0"
                    step="0.01"
                    required>
                </LInput>
            </b-field>
        </div>

        <template #footer>
            <div class="container">
                <div class="columns is-12">
                    <div class="column">
                        <LTooltip
                            :label="$t('admin.payments.modal.footer.tooltipVisibility')"
                            type="is-warning"
                            :active="!visibility"
                            position="is-bottom"
                            expanded>
                            <b-button
                                expanded
                                :disabled="!visibility"
                                native-type="submit"
                                type="is-success is-light">
                                {{ $t("admin.payments.modal.footer.paymentConfirmText") }}
                            </b-button>
                        </LTooltip>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import {
    ADD_PAYMENT_SYSTEM,
    GET_PAYMENT_INTEGRATIONS_LIST,
    GET_PAYMENT_SYSTEMS,
    UPDATE_PAYMENT_FORM_MODAL,
    UPDATE_PAYMENT_SYSTEM
  } from "@core/store/action-constants";
  import {
    SET_CURRENCY,
    UPDATE_MODAL_ACTIVE,
    UPDATE_PAYMENT_INTEGRATIONS_FILTERS,
    UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _isEqual from "lodash/isEqual";
  import { mapActions, mapMutations, mapState } from "vuex";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";
  
  export default {
    name: "PaymentSystemsModal",
    components: {
      ModalBox,
      Select
    },
  
    data () {
      return {
        isLoading: false,
        initialForm: {}
      };
    },
  
    computed: {
      ...mapState("admin/payments/paymentsModal", {
        paymentIntegrationsList: state => state.paymentIntegrationsList.data,
        isPaymentsModalActive: state => state.isPaymentsModalActive,
        editablePaymentId: state => state.editablePaymentId,
        form: state => state.form
      }),

      ...mapState({
        currencyList: ({ auth }) => auth?.availableCurrencies
      }),

      ...mapFields("admin/payments/paymentsModal", {
        fields: ["name", "integrationAlias", "visibility", "currency", "minimalPayout"],
        base: "form",
        action: UPDATE_PAYMENT_FORM_MODAL
      }),

      isModalActive: {
        get () {
          return this.isPaymentsModalActive;
        },

        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
        }
      },

      modalHeader () {
        return this.editablePaymentId ? "editPayment" : "addPayment";
      }
    },
    methods: {
      ...mapMutations("admin/payments/paymentsModal", {
        UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION: `paymentIntegrationsList/${ UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION }`,
        UPDATE_PAYMENT_INTEGRATIONS_FILTERS: `paymentIntegrationsList/${ UPDATE_PAYMENT_INTEGRATIONS_FILTERS }`,
        UPDATE_MODAL_ACTIVE,
        SET_CURRENCY
      }),

      ...mapActions("admin/payments", {
        GET_PAYMENT_SYSTEMS,
        UPDATE_PAYMENT_FORM_MODAL:  `paymentsModal/${ UPDATE_PAYMENT_FORM_MODAL }`,
        ADD_PAYMENT_SYSTEM: `paymentsModal/${ ADD_PAYMENT_SYSTEM }`,
        UPDATE_PAYMENT_SYSTEM: `paymentsModal/${ UPDATE_PAYMENT_SYSTEM }`,
        GET_PAYMENT_INTEGRATIONS_LIST: `paymentsModal/paymentIntegrationsList/${ GET_PAYMENT_INTEGRATIONS_LIST }`
      }),

      async getPaymentIntegrations () {
        this.UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION();
        await this.GET_PAYMENT_INTEGRATIONS_LIST();
        return this.paymentIntegrationsList;
      },

      getVisible () {
        return {
          items: [
            { value: "ALL", label: this.$t("admin.payments.table.values.visibility.ALL") },
            { value: "WEBMASTER", label: this.$t("admin.payments.table.values.visibility.WEBMASTER") },
            { value: "ADVERTISER", label: this.$t("admin.payments.table.values.visibility.ADVERTISER") },
            { value: "NONE", label: this.$t("admin.payments.table.values.visibility.NONE") }
          ] };
      },

      async addPayment () {
        if (this.name === "") {
          this.$buefy.toast.open({
            message: this.$t("admin.payments.messages.errorMessage"),
            type: "is-danger"
          });
        } else {
          this.isLoading = true;

          try {
            await actionWithToast(this.editablePaymentId
              ? this.UPDATE_PAYMENT_SYSTEM()
              : this.ADD_PAYMENT_SYSTEM(), `admin.payments.messages.${ this.modalHeader }`);

            this.isModalActive = false;
          } finally {
            this.isLoading = false;
          }
        }
      },

      closeModalConfirm () {
        if (!_isEqual(this.initialForm, this.form)) {
          this.$buefy.dialog.confirm({
            title: this.$t("common.entity.modal.confirmationToCancel.title"),
            message: this.$t("common.entity.modal.confirmationToCancel.message"),
            confirmText: this.$t("common.entity.modal.confirmationToCancel.confirmText"),
            cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
            type: "is-danger is-light",
            onConfirm: () => {
              this.isModalActive = false;
            }
          });
        } else { this.isModalActive = false; }
      },

      getCurrency () {
        this.SET_CURRENCY();
        const items = this.currencyList;
        return {
          items,
          count: items.length
        };
      }
    },

    watch: {
      currency () {
        const MIN_AMOUNTS = { USD: 20, EUR: 20, RUB: 10000 };
        this.minimalPayout = MIN_AMOUNTS[this.currency];
      }
    }
  };
</script>

<style scoped>

</style>
