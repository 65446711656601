<template>
    <TablePlaceholder :data="payments">
        <template #default="{ items, count }">
            <LTable
                :data="items"
                :loading="isLoading"
                :count="count"
                backend-pagination
                query-pagination
                with-options
                @update="GET_PAYMENT_SYSTEMS">
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.payments.table.labels.id`)">
                    {{ row.id }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.payments.table.labels.name`)">
                    {{ row.name }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.payments.table.labels.currency`)">
                    {{ row.currency }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.payments.table.labels.minimalPayout`)">
                    {{ defaultFormatter(row.minimalPayout) }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.payments.table.labels.visibility`)">
                    {{ $t(`admin.payments.table.values.visibility.${row.visibility}`) }}
                </b-table-column>

                <b-table-column v-slot="{ row }">
                    <LTooltip
                        v-if="row.isDisabled"
                        :label="$t(`admin.payments.table.buttons.dropdown.enable`)"
                        position="is-left"
                        type="is-success">
                        <b-button
                            :loading="loadingRowId === row.id"
                            icon-left="play"
                            rounded
                            size="is-small"
                            type="is-success is-light"
                            @click="enablePaymentsSystem(row.id)">
                        </b-button>
                    </LTooltip>

                    <LTooltip
                        v-else
                        :label="$t(`admin.payments.table.buttons.dropdown.disable`)"
                        position="is-left"
                        type="is-danger">
                        <b-button
                            :loading="loadingRowId === row.id"
                            icon-left="stop"
                            rounded
                            size="is-small"
                            type="is-danger is-light"
                            @click="disablePaymentsSystem(row.id)">
                        </b-button>
                    </LTooltip>
                    
                    <LTooltip
                        :label="$t(`admin.payments.table.buttons.edit`)"
                        position="is-left">
                        <b-button
                            icon-left="pen"
                            :loading="loadingRowId === row.id"
                            rounded
                            size="is-small"
                            type="is-info is-light"
                            @click="showEditModal(row)">
                        </b-button>
                    </LTooltip>
        
                    <LTooltip
                        :label="$t(`admin.payments.table.buttons.delete`)"
                        position="is-left"
                        type="is-danger">
                        <b-button
                            :loading="loadingRowId === row.id"
                            icon-left="trash-alt"
                            icon-pack="far"
                            rounded
                            size="is-small"
                            type="is-danger is-light"
                            @click="onDeletePayment(row)">
                        </b-button>
                    </LTooltip>
                </b-table-column>
            </LTable>
        </template>
    </TablePlaceholder>
</template>

<script>
  import {
    DELETE_PAYMENT_SYSTEM,
    DISABLE_PAYMENT_SYSTEM,
    ENABLE_PAYMENT_SYSTEM,
    GET_PAYMENT_SYSTEMS,
    UPDATE_PAYMENT_FORM
  } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import LTable from "@/components/Common/LTable";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { defaultFormatter } from "../../../core/src/filters";

  export default {
    name: "PaymentsTable",
    components: {
      TablePlaceholder,
      LTable
    },
    
    created () {
      this.GET_PAYMENT_SYSTEMS();
    },

    data () {
      return {
        loadingRowId: null
      };
    },

    computed: {
      ...mapState({
        payments: state => state.admin.payments.payments
      }),

      isLoading () {
        return this.$wait(`admin/payments/${ GET_PAYMENT_SYSTEMS }`);
      }
    },
    methods: {
      defaultFormatter,
      ...mapActions("admin/payments", {
        GET_PAYMENT_SYSTEMS,
        DELETE_PAYMENT_SYSTEM,
        ENABLE_PAYMENT_SYSTEM,
        DISABLE_PAYMENT_SYSTEM,
        UPDATE_PAYMENT_FORM: `paymentsModal/${ UPDATE_PAYMENT_FORM }`,
        UPDATE_MODAL_ACTIVE: `paymentsModal/${ UPDATE_MODAL_ACTIVE }`
      }),

      ...mapMutations("admin/payments", [SET_EMPTY]),

      showEditModal (payment) {
        this.UPDATE_MODAL_ACTIVE(true);
        this.UPDATE_PAYMENT_FORM(payment.id);
      },

      onDeletePayment ({ id }) {
        if (id) {
          this.$buefy.dialog.confirm({
            title: this.$t("admin.payments.modal.confirm.header.delete"),
            confirmText: this.$t("admin.payments.modal.confirm.buttons.confirm"),
            cancelText: this.$t("admin.payments.modal.confirm.buttons.cancel"),
            type: "is-danger is-light",
            onConfirm: async () => {
              try {
                this.loadingRowId = id;
                await actionWithToast(this.DELETE_PAYMENT_SYSTEM(id), "admin.payments.modal.confirm.confirmation");
              } finally {
                this.loadingRowId = null;
              }
            }
          });
        }
      },

      async enablePaymentsSystem (paymentSystemId) {
        try {
          this.loadingRowId = paymentSystemId;
          await actionWithToast(this.ENABLE_PAYMENT_SYSTEM(paymentSystemId), "admin.payments.messages.paymentsOrder.enable.success");
        } finally {
          this.loadingRowId = null;
        }
      },

      disablePaymentsSystem (paymentSystemId) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.payments.modal.header.stopPayments"),
          confirmText: this.$t("admin.payments.modal.footer.stop"),
          cancelText: this.$t("admin.payments.modal.footer.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            try {
              this.loadingRowId = paymentSystemId;
              await actionWithToast(this.DISABLE_PAYMENT_SYSTEM(paymentSystemId), "admin.payments.messages.paymentsOrder.disable.success");
            } finally {
              this.loadingRowId = null;
            }
          }
        });
      }
    },
    
    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>
