<script>
  import Defer from "@core/mixins/defer";
  import LLoading from "@/components/Common/LLoading";

  export default {
    name: "AsyncRender",
    render: function (createElement) {
      const defaultElement = createElement("span");
      const slots = [defaultElement, ...this.$slots.default];
      const result = slots.filter((item, i) => {
        return this.defer(i+1);
      });
      return createElement("section", result.slice(1));

    },
    components: {
      LLoading
    },

    mixins: [Defer(10)]
  };
</script>

<style scoped>

</style>