<template>
    <SectionWrapper
        :action-button-options="actionButtonOptions"
        @action="openModal">
        <template #sticky>
            <span></span>
        </template>
        <PaymentsTable></PaymentsTable>
        <PaymentSystemsModal></PaymentSystemsModal>
    </SectionWrapper>
</template>

<script>
  import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import PaymentsTable from "@/components/Admin/Payments/PaymentsTable";
  import PaymentSystemsModal from "@/components/Admin/Payments/PaymentSystemsModal";
  
  export default {
    name: "Payments",
    components: {
      PaymentSystemsModal,
      PaymentsTable,
      SectionWrapper
    },

    computed: {
      actionButtonOptions () {
        return {
          label: this.$t("admin.payments.tooltip.addPayment")
        };
      }
    },

    methods: {
      ...mapActions("admin/payments", {
        UPDATE_MODAL_ACTIVE: `paymentsModal/${ UPDATE_MODAL_ACTIVE }`
      }),

      openModal () {
        this.UPDATE_MODAL_ACTIVE(true);
      }
    }
  };
</script>

<style scoped></style>
